<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <div class="sc__welcome">
          <BreadcrumbsComponent title="Общественный контроль" />
          <div class="sc__welcome-blocks">
            <div class="sc__welcome-block">
              <img src="/public/images/sc/sc_1.svg" alt="" />
              <span>
                Сообщите <br />
                о проблеме
              </span>
            </div>
            <div class="sc__welcome-block">
              <img src="/public/images/sc/sc_2.svg" alt="" />
              <span>
                Мы <br />
                устраним
              </span>
            </div>
            <div class="sc__welcome-block">
              <img src="/public/images/sc/sc_3.svg" alt="" />
              <span>
                Вы контролируете <br />
                качество
              </span>
            </div>
          </div>
          <div class="sc__welcome-bottom">
            <div class="sc-stats__item">
              <div class="sc-stats__number">{{ usersCount || 0 }}</div>
              <div class="sc-stats__title">
                зарегистрировано <br />
                пользователей
              </div>
            </div>
            <div class="sc-stats__item">
              <div class="sc-stats__number">{{ resolvedClaimsCount || 0 }}</div>
              <div class="sc-stats__title">
                решённых <br />
                проблем
              </div>
            </div>
            <router-link :to="{ name: 'sc_map' }" class="sc__report-link btn-blue"
              >Сообщить о проблеме</router-link
            >
          </div>
        </div>
        <section class="section section-pb48">
          <h3 class="sc__title">Результаты работы</h3>
          <LoadingIndicator v-if="loading" title="Загрузка" />
          <div v-else-if="scResults && scResults.data && scResults.data.length" class="sc__results-list">
            <div v-for="(item, index) in scResults.data" :key="index" class="sc__results-item">
              <div class="sc__results-image">
                <ImagesComparisonComponent
                  :leftImage="item.head_img | image($store.state.api)"
                  :rightImage="item.report_img | image($store.state.api)"
                />
              </div>
              <a v-if="item.address" href="#" class="sc__results-title">
                {{ item.address }}
              </a>
              <div v-if="item.category && item.category.title" class="sc__results-bottom">
                <span>{{ item.category.title }}</span>
                <time class="sc__results-item-date" :datetime="item.date_publication | robotDate">
                  {{ item.date_publication | humanDateRu }}
                </time>
              </div>
            </div>
          </div>
          <p v-else class="_item__text">Информация отсутствует</p>
          <a
            v-if="scResults && scResults.paginatorInfo && scResults.paginatorInfo.hasMorePages"
            @click.prevent="loadMore"
            class="sc__show-more show__more__link"
            href="#"
          >
            Показать ещё
          </a>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImagesComparisonComponent from "components/ImagesComparisonComponent.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import SC_HOME_PAGE from "@/graphql/sc/HomePage.graphql";

export default {
  name: "SocialControl",
  components: {
    BreadcrumbsComponent,
    ImagesComparisonComponent,
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
      page: 1,
    };
  },
  created() {
    // this.$store.dispatch("SC_HOME", this.$apollo.provider)
    this.loadMore();
  },
  computed: {
    scResults() {
      if (this.$store.state.socialControlHome) {
        return this.$store.state.socialControlHome.PaginateProjects;
      }
      return {};
    },
    usersCount() {
      if (this.$store.state.socialControlHome) {
        return this.$store.state.socialControlHome.UsersCount;
      }
      return 0;
    },
    resolvedClaimsCount() {
      if (this.$store.state.socialControlHome) {
        return this.$store.state.socialControlHome.ResolvedClaimsCount;
      }
      return 0;
    },
  },
  methods: {
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        await this.$apollo.provider.clients.sc
          .query({
            query: SC_HOME_PAGE,
            variables: Object.assign({
              page:
                this.scResults && this.scResults.paginatorInfo && this.scResults.paginatorInfo.hasMorePages
                  ? this.scResults.paginatorInfo.currentPage + 1
                  : this.page,
            }),
          })
          .then(({ data }) => {
            this.loading = false;
            this.$store.commit("socialControlHome", data);
          });
      }
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sc_results.styl"

.sc__welcome {
  position relative
  background-image linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%, #DDE8EF 100%)
  border-bottom 1px solid var(--color_gray_divider)

  &-blocks {
    display flex
    align-items center
    justify-content space-between
    padding 105px 64px 132px
    gap 32px
    overflow-x auto
    background-image url("/public/images/sc/sc_welcome.png")
    background-repeat no-repeat
    background-size auto
    background-position: center;
    +below(880px) {
      padding-left 24px
      padding-right 24px
      gap 64px
    }
    +below(768px) {
      padding-top 60px
      padding-bottom 60px
    }

    &::-webkit-scrollbar {
      display none
    }

    img {
      width 128px
      min-width 128px
      height 128px
      object-fit contain
      +below(1500px) {
        width: 80px
        min-width 80px
        height: 80px
      }
    }
  }

  &-block {
    flex-shrink 0
    display flex
    align-items center
    gap 16px
    +below(1200px) {
      flex-direction column
      align-items flex-start
    }

    span {
      flex-shrink 0
      font-weight: 600;
      font-size: 1.5em
      line-height: 40px;
      text-transform: uppercase;
      +below(1500px) {
        font-size: 1.25em
        line-height: 32px
      }
    }
  }

  &-bottom {
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    padding 0 64px 24px
    gap 24px
    +below(880px) {
      padding 10px 24px 16px
    }
    +below(620px) {
      display grid
      grid-template-columns 1fr 1fr
      align-items flex-start
      gap 16px
      padding-top 10px
    }
    +below(480px) {
      grid-template-columns 1fr
    }

    .sc-stats__item {
      display flex
      align-items center
      justify-content: flex-end;
      color var(--color_dark)
      gap 16px
      flex-shrink 0
      +below(768px) {
        gap 8px
      }
      +below(620px) {
        justify-content: flex-start;
      }
    }

    .sc-stats__number {
      font-weight: 500;
      font-size: 3em
      line-height: 44px;
      +below(920px) {
        font-size: 2.5em
        line-height: 40px;
      }
      +below(768px) {
        font-size: 2em
      }
    }

    .sc-stats__title {
      font-size: 1.125em
      line-height: 22px;
      +below(920px) {
        font-size: 1em
        line-height: 20px
      }
      +below(768px) {
        font-size: 0.875em
        line-height: 16px;
      }
    }

    .sc__report-link {
      padding 20px 32px
      box-shadow: 5px 6px 12px var(--color_black_o08)
      border-radius: 4px;
      font-weight: 500;
      line-height: 24px;
      width 360px
      height auto
      border none
      +below(990px) {
        width auto
      }
      +below(920px) {
        font-size: 1em
        line-height: 24px;
        padding 10px 17px
      }
      +below(768px) {
        font-size: 0.875em
        line-height: 20px;
        min-height 40px
      }
    }
  }
}

.sc__title {
  font-size 1.5em
  line-height 125%
}

.sc__show-more {
  margin-top: 24px;
}
</style>
